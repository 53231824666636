@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
	--forest-green: #225324;
	--forest-green-filter: invert(20%) sepia(15%) saturate(5175%) hue-rotate(88deg) brightness(87%) contrast(74%);
}

a {
	color: #225324;
	color: var(--forest-green);
	text-decoration: none;
}

.App {
	text-align: center;
	-webkit-align-items: center;
	        align-items: center;
	font-family: "Source Sans Pro";
	background-color: #FFFFFF;
}

.App-logo {
	height: 60vmin;
	pointer-events: none;
}

.App-logo:hover {
	opacity: 0.6;
}

.App-header {
	margin: auto;
	min-height: 20vh;
	max-width: 120vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-header-image {
	height: 10vmin; 
}

.App-underline {
	margin-top: 1vmin;
	width: 30vmin;
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg)
}

.App-home-text {
	height: 7vmin;
	margin-top: 2vmin;
	color: #225324;
	color: var(--forest-green)
}

.App-home-text:hover {
	opacity: 0.6;
}

.App-content {
	min-height: 60vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	font-size: calc(10px + 2vmin);
	font-family: "Baskerville";
	color: #225324;
	color: var(--forest-green);
}

.App-link {
	color: #61dafb;
}

.Link-image{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

/*
******* NAVIGATION BAR *******
*/

.Navigation-bar {
	height: 10vmin;
	width: 50vmin;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.Navigation-bar-button {
	height: 2vmin;
	cursor: pointer;
}

.Navigation-bar-button:hover {
	fill: #225324;
	fill: var(--forest-green)
}

.Navigation-bar-menu-icon {
	height: 5vmin;
	cursor: pointer;
	margin-bottom: 3vmin;
}

.Navigation-bar-separator {
	height: 2.5vmin;
	fill: #225324;
	fill: var(--forest-green)
}

.Navigation-bar-separator-margin {
	margin: 0.7vmin
}

/*
******* SOCIAL MEDIA BAR *******
*/

.Social-media-bar {
	min-height: 20vh;
	width: 15vh;
	margin: auto;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.Social-media-bar img:hover {
	opacity: 0.5;
}

.Social-media-icon {
	height: 3vh;
	fill: black;
}

.Social-media-icon:hover {
	fill: #225324;
	fill: var(--forest-green);
}

/*
******* FEATURE BOX *******
*/

.Feature-box {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-bottom: 5vh;
}

.Feature-box-image {
	width: 30vw;
}

.Feature-box-image:hover {
	opacity: 0.7
}

.Feature-box-title-image {
	height: 5vmin;
	max-width: 30vw;
}

.Feature-box-title-image:hover {
	fill: #225324;
	fill: var(--forest-green);
	-webkit-filter: invert(20%) sepia(15%) saturate(5175%) hue-rotate(88deg) brightness(87%) contrast(74%);
	        filter: invert(20%) sepia(15%) saturate(5175%) hue-rotate(88deg) brightness(87%) contrast(74%);
	-webkit-filter: var(--forest-green-filter);
	        filter: var(--forest-green-filter);
}

.Feature-box-body-image {
	height: 2vmin;
	fill: black;
}

.Feature-box-body-image:hover {
	fill: #225324;
	fill: var(--forest-green)
}

.Feature-box-body-image-separator {
	height: 2vmin;
	fill: #225324;
	fill: var(--forest-green);
}

.Feature-box-text {
	width: 30vw;
}

/*
******* ABOUT PAGE *******
*/

.About-page {
	min-height: 60vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-justify-content: center;
	        justify-content: center;
}

.About-page-press-shot {
	width: 30vw;
}

.About-page-photo-text {
	font-size: calc(3px + 1vmin);
	text-align: left;
}

.About-page-text-box {
	width: 30vw;
	text-align: left;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.About-page-feature-text {
	font-family: "Baskerville";
	font-size: calc(10px + 2vmin);
	padding-left: 5vw;
	color: green;
}

.About-page-text-spacer {
	height: 5vh;
}

.About-page-body-text {
	font-size: calc(16px);
	font-weight: lighter;
	padding-left: 5vw;
	color: rgba(38,51,51,1);
}

/*
******* CONTACT PAGE *******
*/

.Contact-page {
	min-height: 60vh;
	margin: auto;
}

/*
******* SMART LINKS *******
*/

.Smart-links {
	min-height: 60vh;
	margin: auto;
	font-size: calc(10px + 2vmin);
	color: #225324;
	color: var(--forest-green);
	margin-bottom: 20vmin;
	font-family: "Baskerville";
	line-height: 8vmin;
}

.Smart-link-button {
	border-style: solid;
	margin: auto;
	padding: 1vmin;
}

.Smart-link-button:hover {
	opacity: 0.6
}

.Smart-link-single-artwork {
	height: 50vmin;
	margin: 1vmin;
}

.Smart-link-single-artwork:hover {
	opacity: 0.6;
}

/*
******* MOBILE DEVICES *******
*/

@media (max-width: 1000px) {
	.App-header {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}

	.App-header-image {
		height: 18vmin; 
	}

	.Navigation-bar {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
		height: 45vmin;
		margin-bottom: 5vmin;
	}

	.Navigation-bar-button {
		height: 5vmin;
	}

	.Feature-box-image {
		width: 40vw;
	}

	.Feature-box-text {
		width: 40vw;
	}

	.Feature-box-body-image {
		height: 3vmin;
	}

	.Feature-box-body-image-separator {
		height: 3vmin;
	}

	.About-page {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.About-page-press-shot {
		width: 80vw;
	}

	.About-page-photo-text {
		font-size: calc(3px + 2vmin);
		text-align: left;
	}

	.About-page-text-box {
		width: 80vw;
		padding-top: 5vh;
	}

	.About-page-feature-text {
		padding-left: 0vw;
	}

	.About-page-body-text {
		padding-left: 0vw;
	}

	.Social-media-bar {
		width: 25vh;
	}

	.Social-media-icon {
		height: 5vh;
	}

	.Smart-links {
		min-height: 60vh;
		margin: auto;
		font-size: calc(10px + 5vmin);
		color: #225324;
		color: var(--forest-green);
		margin-bottom: 20vmin;
		font-family: "Baskerville";
		line-height: 15vmin;
	}

	.Smart-link-button {
		margin-bottom: 4vmin;
	}

	.Smart-link-single-artwork {
		height: 70vmin;
		margin: 3vmin;
	}

	.Smart-link-single-artwork:hover {
		opacity: 0.8;
	}
}

